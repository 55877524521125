<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('STOCK_ENTRY.TITLE_NEW') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'companyStockEntryIndex' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12" md="8" offset-md="2">
            <b-form-group id="select-group-2" :label="`${$t('STOCK_ENTRY.PRODUCT')}:`" label-cols-md="2" label-align-md="right" label-for="select-2">
              <SelectInfinityScroll
                ref="selectClient"
                id="select-2"
                v-model="$v.form.product.$model"
                :search.sync="search"
                api="stock/products"
                :state="validateState('product') === false"
                @input="clearServerError('product')"
                clearable
              />
            </b-form-group>
            <b-form-group :label="`${$t('STOCK_ENTRY.QUANTITY')}`" label-for="input-1" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-1"
                v-model="$v.form.quantity.$model"
                :state="validateState('quantity')"
                :placeholder="$t('PLACEHOLDER.NAME')"
                type="number"
                trim
                @input="clearServerError('quantity')"
                aria-describedby="input-1-feedback"
              />
              <b-form-invalid-feedback id="input-1-feedback">
                <template v-if="serverErrors.quantity">{{ serverErrors.quantity[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('STOCK_ENTRY.QUANTITY') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="`${$t('STOCK_ENTRY.RATE')}`" label-for="input-2" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-2"
                v-model="$v.form.cost.$model"
                :state="validateState('quantity')"
                :placeholder="$t('PLACEHOLDER.NAME')"
                type="number"
                trim
                @input="clearServerError('cost')"
                aria-describedby="input-1-feedback"
              />
              <b-form-invalid-feedback id="input-1-feedback">
                <template v-if="serverErrors.cost">{{ serverErrors.cost[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('STOCK_ENTRY.RATE') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="select-group-1"
              :label="`${$t('STOCK_ENTRY.WAREHOUSE')}:`"
              label-cols-md="2"
              label-align-md="right"
              label-for="select-1"
            >
              <SelectInfinityScroll
                ref="selectClient"
                id="select-1"
                v-model="$v.form.warehouse.$model"
                :search.sync="search"
                api="stock/warehouses"
                :state="validateState('warehouse') === false"
                @input="clearServerError('warehouse')"
                clearable
              />
            </b-form-group>
            <b-form-group
              :label="`${$t('STOCK_ENTRY.PRODUCT_FROM')}`"
              v-slot="{ ariaDescribedby }"
              class="align-items-center"
              label-for="radio-1"
              label-cols-md="2"
              label-align-md="right"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="source"
                :aria-describedby="ariaDescribedby"
              >
                <b-form-radio
                  value="SUPPLIER"
                >
                  Supplier
                </b-form-radio>
                <b-form-radio
                  value="WAREHOUSE"
                >
                  Warehouse
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group
              v-if="source === 'SUPPLIER'"
              id="select-group-3"
              key="selectSource"
              :label="`${$t('STOCK_ENTRY.SOURCE_SUPPLIER')}:`"
              label-cols-md="2"
              label-align-md="right"
              label-for="select-3"
            >
              <SelectInfinityScroll
                ref="selectSource"
                id="select-3"
                v-model="$v.form.sourceSupplier.$model"
                :search.sync="search"
                api="stock/product-suppliers"
                :state="validateState('sourceSupplier') === false"
                @input="clearServerError('sourceSupplier')"
                clearable
              />
            </b-form-group>
            <b-form-group
              v-if="source === 'WAREHOUSE'"
              id="select-group-4"
              key="selectWarehouse"
              :label="`${$t('STOCK_ENTRY.SOURCE_WAREHOUSE')}:`"
              label-cols-md="2"
              label-align-md="right"
              label-for="select-4"
            >
              <SelectInfinityScroll
                ref="selectWarehouse"
                id="select-4"
                v-model="$v.form.sourceWarehouse.$model"
                :search.sync="search"
                api="stock/warehouses"
                :state="validateState('sourceWarehouse') === false"
                @input="clearServerError('sourceWarehouse')"
                clearable
              />
            </b-form-group>
            <div class="text-right">
              <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="primary" size="sm">
                <b-spinner v-if="loading" small variant="light" />
                {{ $t('FORM.SUBMIT') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </template>
</KTCard>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, numeric } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/stockEntryRepository'
import { TIMEOUT_REDIRECT } from '@/constants/base'
import KTCard from '../../../content/Card'
import serverVuelidate from '../../../../mixins/serverVuelidate'
import SelectInfinityScroll from '../../../../components/forms-items/SelectInfinityScroll'
import InputForm from '../../../../components/forms-items/input'

export default {
  name: 'CompanyWarehousesCreate',
  components: {
    KTCard,
    SelectInfinityScroll,
    InputForm,
  },
  mixins: [
    validationMixin,
    serverVuelidate,
  ],
  data() {
    return {
      loading: false,
      source: 'SUPPLIER',
      form: {
        quantity: 0,
        product: '',
        warehouse: '',
        sourceWarehouse: '',
        sourceSupplier: '',
        cost: 0,
      },
      validations: {
        form: {
          quantity: {
            required,
            numeric,
          },
          product: { required },
          warehouse: { required },
          sourceWarehouse: {},
          sourceSupplier: {},
          cost: { numeric },
        },
      },
      search: '',
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('STOCK_ENTRY.TITLE_NEW') }])
  },
  methods: {
    resetForm() {
      this.form = {
        name: '',
        outer: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true
      const obj = { ...this.form }
      obj.warehouse = obj.warehouse ? obj.warehouse.uuid : ''
      obj.product = obj.product ? obj.product.uuid : ''
      obj.sourceSupplier = obj.sourceSupplier ? obj.sourceSupplier.uuid : ''
      obj.sourceWarehouse = obj.sourceWarehouse ? obj.sourceWarehouse.uuid : ''
      Repo.post(obj).then(() => {
        this.$bvToast.toast(this.$t('TOAST.CREATED'), {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2000,
          solid: true,
        })
        this.resetForm()
        setTimeout(() => this.$router.push({ name: 'companyStockEntryIndex' }), TIMEOUT_REDIRECT)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.seServerErrors(err.response.data)
      })
    },
  },
}
</script>

<style scoped>

</style>
